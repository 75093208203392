import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { classNames } from "@/utils/styling-utils";
import type { BlocksContent } from "@strapi/blocks-react-renderer";
import { Heading } from "../base/heading";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import type { HorizontalAlignmentType } from "../layout/layout.model";
import { VerticalContainer } from "../layout/vertical-container";
import { LinkButton } from "./link-button";
import RichTextJsonRenderer from "./rich-text-json-renderer";
import type { TextTheme } from "./ui.model";

interface RichTextBlockProps {
  alignButtonHorizontally?: HorizontalAlignmentType;
  alignTextHorizontally?: HorizontalAlignmentType;
  alignTitleHorizontally?: HorizontalAlignmentType;
  buttonText?: string;
  buttonUrl?: string;
  id?: string;
  jsonRichTextContent: BlocksContent;
  textTheme: TextTheme;
  title?: string;
}

export const RichTextBlock = ({
  alignButtonHorizontally,
  alignTextHorizontally,
  alignTitleHorizontally,
  buttonText,
  buttonUrl,
  id,
  jsonRichTextContent,
  textTheme,
  title,
}: RichTextBlockProps) => {
  // Ensure a value that is consistently defined when prop drilling
  const derivedAlignTextHorizontally: HorizontalAlignmentType =
    (alignTextHorizontally as HorizontalAlignmentType) ?? "left";
  const derivedAlignTitleHorizontally: HorizontalAlignmentType =
    (alignTitleHorizontally as HorizontalAlignmentType) ?? "left";
  const derivedAlignButtonHorizontally: HorizontalAlignmentType =
    (alignButtonHorizontally as HorizontalAlignmentType) ?? "left";

  const scrollTo = useScrollToElement(id);

  return (
    <div
      className={classNames("rich-text-block", textTheme && `rich-text-block--text-theme-${textTheme}`)}
      id={scrollTo ?? ""}
    >
      {title && (
        <>
          <HorizontalContainer horizontalPlacement={derivedAlignTitleHorizontally} width="full">
            <Heading type="h2">{title}</Heading>
          </HorizontalContainer>
          <HorizontalSpacer />
        </>
      )}
      <VerticalContainer horizontalPlacement={derivedAlignTextHorizontally} width="full">
        <RichTextJsonRenderer
          content={jsonRichTextContent}
          textTheme={textTheme}
          alignTextHorizontally={derivedAlignTextHorizontally}
        />
      </VerticalContainer>

      {buttonUrl && buttonText && (
        <>
          <HorizontalSpacer />
          <HorizontalContainer horizontalPlacement={derivedAlignButtonHorizontally} width="full">
            <LinkButton buttonType="secondary" href={buttonUrl}>
              {buttonText}
            </LinkButton>
          </HorizontalContainer>
        </>
      )}
    </div>
  );
};
