import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

/**
 * Hvis man vil linke til en komponent, slik at den scroller til midten av siden, må man gjøre følgende:
 * 1. Sette id for komponenten:
 *    id-til-komponenten
 * 2. Opprette link som som har 'scroll-to=[id]' i querystringen:
 *    <a href="/noe-her?scroll-to=id-til-komponenten">Linktekst</a>
 */
export const useScrollToElement = (id: string | undefined, callback?: () => void) => {
  const searchParams = useSearchParams();
  const scrollThisIdIntoView = searchParams.get("scroll-to");

  // id blir gjort om til lowercase og med bindestreker i stedet for mellomrom
  const scrollTo = id ? id.replace(/\s+/g, "-").toLowerCase() : undefined;

  useEffect(() => {
    if (scrollTo && scrollThisIdIntoView) {
      // hvis search param 'scroll-to' er lik scrollTo (id): scroll component into view
      if (scrollTo === scrollThisIdIntoView) {
        const scrollElement = document.getElementById(scrollTo);

        if (scrollElement !== null) {
          if (callback) {
            callback();
          }

          // scroll component into center of viewport
          scrollElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
  }, [callback, scrollTo, scrollThisIdIntoView]);

  return scrollTo;
};
