import { VerticalContainer } from "../layout/vertical-container";
import { BookingFilterSkeleton } from "./booking-filter-skeleton";
import { TrainerGridSkeletons } from "./trainer-grid-skeletons";

export const PtFinderSkeleton = () => (
  <>
    <BookingFilterSkeleton />
    <VerticalContainer width="full">
      <TrainerGridSkeletons />
    </VerticalContainer>
  </>
);
