import type { ReactNode } from "react";

import type { FilterTheme } from "@/utils/filter-utils";
import { classNames } from "@/utils/styling-utils";
import ContentLoader from "react-content-loader";

interface FilterButtonSmallProps {
  children: ReactNode;
  onClick: () => void;
  selected?: boolean;
  theme?: FilterTheme;
  width: number;
}

export const FilterButtonSmall = ({ children, onClick, selected, theme, width }: FilterButtonSmallProps) => (
  <div
    className={classNames(
      "filter-button-small",
      selected && "filter-button-small--selected",
      theme && `filter-button-small--${theme}`,
    )}
    style={{ width: `${width}%` }}
  >
    <button className="filter-button-small__button" onClick={onClick} type="button">
      <div className="filter-button-small__button__visible">{children}</div>
    </button>
  </div>
);

export const FilterButtonSmallSkeleton = () => (
  <div className={classNames("filter-button-small")} style={{ width: `${100}%` }}>
    <button className="filter-button-small__button" type="button">
      <ContentLoader height="61" width="100%">
        <rect height="100%" width="100%" />
      </ContentLoader>
    </button>
  </div>
);
