import { Fragment, Suspense } from "react";

import { type BlockUnionFragment, FilterTypes } from "@/generated/client.generated";
import { mapSpacingToHorizontalSpacerProps } from "@/utils/spacing-mapper";
import type { ButtonType } from "../base/button";
import { BookingNextActivitiesForFilters } from "../booking/booking-next-activities/booking-next-activities-for-filters";
import { BookingNextEventsForFilters } from "../booking/booking-next-events/booking-next-events-for-filters";
import { BookingNextEventsForFiltersSwiper } from "../booking/booking-next-events/booking-next-events-for-filters-swiper";
import { ContentContainer } from "../layout/content-container";
import { FullWidthContainer } from "../layout/full-width-container";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import type { HorizontalAlignmentType } from "../layout/layout.model";
import { TrenerGalleri } from "../trainer/trener-galleri";
import { CampaignBlock } from "./campaign-block";
import { Countdown, mapInputStringToCountdownGranularityEnum } from "./countdown";
import { Details, DetailsArea } from "./details";
import { DetailsLookalikeLink } from "./details-lookalike-link";
import { EmbeddedVideo } from "./embedded-video";
import { FlexCard, type FlexCardDirectionOnDesktop } from "./flex-card";
import {
  ImageHeadingAndTextCard,
  type ImageHeadingAndTextCardHorizontalAlignment,
  type ImageHeadingAndTextCardTextLayout,
  type ImageHeadingAndTextCardTextSize,
} from "./image-heading-and-text-card";
import { LinkButton } from "./link-button";
import { Markdown } from "./markdown";
import { Media } from "./media";
import { MediaCard, type MediaCardHorizontalAlignmentType, type MediaCardVerticalAlignmentType } from "./media-card";
import { NewsCard } from "./news-card";
import { NoticeWithMessage } from "./notice-with-message";
import { RichTextBlock } from "./rich-text-block";
import RichTextJsonRenderer from "./rich-text-json-renderer";
import { SellingPoints } from "./selling-points";
import { SubscriptionCardFilter } from "./subscription-card-filter";
import { Swiper } from "./swiper";
import { Text } from "./text";
import { TextBlock } from "./text-block";
import { stringToTextTheme } from "./ui.model";

interface BlockProps {
  isSidebar?: boolean;
  parts: BlockUnionFragment;
}

function SpacerBelowBlock({ size }: { size: string | null }) {
  const spacer = size === "none" ? <></> : <HorizontalSpacer size={mapSpacingToHorizontalSpacerProps(size)} />;
  return spacer;
}

export function Block({ isSidebar, parts }: BlockProps) {
  switch (parts.__typename) {
    case "MultipleMediaWithButton": {
      if (parts.items.length === 0) {
        return <></>;
      }

      const isSlider = parts.items.length > 2;

      const multipleMediaWithButtonContent = parts.items.map((item) => {
        return (
          <MediaCard
            alignContentHorizontally={item.alignContentHorizontally as MediaCardHorizontalAlignmentType}
            alignContentVertically={item.alignContentVertically as MediaCardVerticalAlignmentType}
            blockMargin={parts.items.length === 2} // margin når det er 2 av samme komponent, kan kun brukes i kombinasjon med FullWidthContainer
            buttonText={item.buttonText ?? undefined}
            fallbackAlt="Bildetekst"
            heading={item.title ?? undefined}
            isSlider={isSlider}
            key={item.title + item.url}
            media={item.responsiveMedia}
            text={item.message}
            textTheme={stringToTextTheme(item.textTheme)} // TODO: generell strategi for enums fra Strapi, så vi unngår feil pga. lowercase/uppercase > ALL CAPS?
            url={item.url}
          />
        );
      });

      // Vises under hverandre på mobil/ved siden av hverandre på desktop hvis det er akkurat 2 elementer
      if (parts.items.length === 2) {
        return (
          <FullWidthContainer>
            <ContentContainer horizontalPlacement="center" width="x-large">
              <HorizontalContainer horizontalPlacement="center" verticalPlacement="top" width="full">
                {multipleMediaWithButtonContent}
              </HorizontalContainer>

              <SpacerBelowBlock size={parts.spacerBelowBlock} />
            </ContentContainer>
          </FullWidthContainer>
        );
      }

      return (
        <>
          {/* Viser som swiper hvis det er flere enn 2 items */}
          {isSlider ? (
            parts.sliderFullWidth ? (
              <Swiper fullWidth={parts.sliderFullWidth} isSidebar={isSidebar} items={multipleMediaWithButtonContent} />
            ) : (
              <ContentContainer horizontalPlacement="center" width="x-large">
                <Swiper isSidebar={isSidebar} items={multipleMediaWithButtonContent} />
              </ContentContainer>
            )
          ) : (
            multipleMediaWithButtonContent
          )}
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MultipleMediaWithText": {
      if (parts.items.length === 0) {
        return <></>;
      }

      const isSlider = parts.items.length > 2;

      const multipleMediaWithTextContent = parts.items.map((item, index) => {
        return (
          <ImageHeadingAndTextCard
            alignContentHorizontally={item.alignContentHorizontally as ImageHeadingAndTextCardHorizontalAlignment}
            blockMargin={parts.items.length === 2} // margin når det er 2 av samme komponent, kan kun brukes i kombinasjon med FullWidthContainer
            fallbackAlt="Bildetekst"
            fullWidth={item.fullWidth}
            heading={item.heading ?? undefined}
            id={item.scrollToId || undefined}
            isSlider={isSlider}
            isSliderFullWidth={parts.sliderFullWidth}
            key={index}
            media={item.responsiveMedia}
            text={item.text ?? undefined}
            textLayout={item.textLayout as ImageHeadingAndTextCardTextLayout}
            textSize={item.textSize as ImageHeadingAndTextCardTextSize}
            textTheme={stringToTextTheme(item.optionalTextTheme)} // TODO: generell strategi for enums fra Strapi, så vi unngår feil pga. lowercase/uppercase > ALL CAPS?
            url={item.nullableUrl ?? undefined}
          />
        );
      });

      // Vises under hverandre på mobil/ved siden av hverandre på desktop hvis det er akkurat 2 elementer
      if (parts.items.length === 2) {
        return (
          <FullWidthContainer>
            <ContentContainer horizontalPlacement="center" width="x-large">
              <HorizontalContainer horizontalPlacement="center" verticalPlacement="top" width="full">
                {multipleMediaWithTextContent}
              </HorizontalContainer>

              <SpacerBelowBlock size={parts.spacerBelowBlock} />
            </ContentContainer>
          </FullWidthContainer>
        );
      }

      return (
        <>
          {/* Viser som swiper hvis det er flere enn 1 item */}
          {isSlider ? (
            parts.sliderFullWidth ? (
              <Swiper fullWidth={parts.sliderFullWidth} isSidebar={isSidebar} items={multipleMediaWithTextContent} />
            ) : (
              <ContentContainer horizontalPlacement="center" width="x-large">
                <Swiper isSidebar={isSidebar} items={multipleMediaWithTextContent} />
              </ContentContainer>
            )
          ) : (
            multipleMediaWithTextContent
          )}
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MultipleNewsCard": {
      if (parts.items.length === 0) {
        return <></>;
      }

      const multipleNewsCardContent = parts.items.map((item, index) => {
        return (
          <NewsCard
            blockMargin={parts.items.length === 2} // margin når det er 2 av samme komponent, kan kun brukes i kombinasjon med FullWidthContainer
            buttonText={item.buttonText ?? undefined}
            fallbackAlt="Nyhetstekst"
            heading={item.heading ?? ""}
            id={item.scrollToId || undefined}
            key={index}
            media={item.responsiveMedia}
            text={item.text ?? undefined}
            url={item.nullableUrl ?? undefined}
          />
        );
      });

      // Vises under hverandre på mobil/ved siden av hverandre på desktop hvis det er akkurat 2 elementer
      if (parts.items.length === 2) {
        return (
          <>
            <FullWidthContainer>
              <HorizontalContainer horizontalPlacement="center" verticalPlacement="top" width="full">
                {multipleNewsCardContent}
              </HorizontalContainer>
            </FullWidthContainer>

            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </>
        );
      }

      return (
        <>
          {/* Viser som swiper hvis det er flere enn 1 item */}
          {parts.items.length > 2 ? (
            <ContentContainer horizontalPlacement="center" width="x-large">
              {/* NB: Swiper for NewsCard skal ikke kunne gå i full bredde */}
              <Swiper isSidebar={isSidebar} items={multipleNewsCardContent} />
            </ContentContainer>
          ) : (
            multipleNewsCardContent
          )}
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MultipleNoticeWithMessage": {
      if (parts.items.length === 0) {
        return <></>;
      }

      return (
        <>
          {parts.items.map((notice, index) => {
            return (
              <Fragment key={index}>
                <NoticeWithMessage parts={notice} />
                <HorizontalSpacer />
              </Fragment>
            );
          })}
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "KommendeTimer": {
      return (
        <>
          <BookingNextActivitiesForFilters
            currentFilters={[
              ...parts.filter.map((filter) => {
                return { type: filter.type, value: filter.value };
              }),
              { type: FilterTypes.IncludeBarnepass, value: String(true) },
            ]}
            take={parts.numberOfActivities}
            title={parts.heading}
            seeMoreOfThis={parts.buttonText && parts.url ? { text: parts.buttonText, url: parts.url } : undefined}
            showWeekDayFilter={parts.showWeekdayFilter}
            backgroundColor={parts.backgroundColor ?? undefined}
            textTheme={parts.kommendeTimerTextTheme}
            activityStartFilter={parts.activityStartFilter ?? undefined}
            activityEndFilter={parts.activityEndFilter ?? undefined}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "KommendeEvents": {
      switch (parts.displayMode) {
        case "swiper": {
          return (
            <>
              <BookingNextEventsForFiltersSwiper
                currentFilters={parts.filter.map((filter) => {
                  return { type: filter.type, value: filter.value };
                })}
                take={parts.numberOfEvents}
                title={parts.heading}
                seeMoreOfThis={parts.buttonText && parts.url ? { text: parts.buttonText, url: parts.url } : undefined}
                isSidebar={isSidebar}
              />
              <SpacerBelowBlock size={parts.spacerBelowBlock} />
            </>
          );
        }
        case "list": {
          return (
            <>
              <BookingNextEventsForFilters
                currentFilters={parts.filter.map((filter) => {
                  return { type: filter.type, value: filter.value };
                })}
                take={parts.numberOfEvents}
                title={parts.heading}
                seeMoreOfThis={parts.buttonText && parts.url ? { text: parts.buttonText, url: parts.url } : undefined}
                backgroundColor={parts.backgroundColor ?? undefined}
                textTheme={parts.kommendeEventsTextTheme}
              />
              <SpacerBelowBlock size={parts.spacerBelowBlock} />
            </>
          );
        }
        default: {
          return (
            <>
              <BookingNextEventsForFilters
                currentFilters={parts.filter.map((filter) => {
                  return { type: filter.type, value: filter.value };
                })}
                take={parts.numberOfEvents}
                title={parts.heading}
                seeMoreOfThis={parts.buttonText && parts.url ? { text: parts.buttonText, url: parts.url } : undefined}
                backgroundColor={parts.backgroundColor ?? undefined}
                textTheme={parts.kommendeEventsTextTheme}
              />
              <SpacerBelowBlock size={parts.spacerBelowBlock} />
            </>
          );
        }
      }
    }

    case "FlexCard": {
      const flexCardContent = (
        <FlexCard
          buttonText={parts.buttonText || undefined}
          directionOnDesktop={parts.directionOnDesktop as FlexCardDirectionOnDesktop}
          fallbackAlt={parts.nullableHeading || "Bildetekst"}
          id={parts.scrollToId || undefined}
          media={parts.media}
          heading={parts.nullableHeading || undefined}
          isSidebar={isSidebar}
          text={parts.richText || parts.nullableText || undefined}
          textTheme={stringToTextTheme(parts.textTheme)}
          url={parts.url || undefined}
        />
      );

      if (parts.fullWidth === true) {
        if (parts.backgroundColor) {
          return (
            <>
              <FullWidthContainer backgroundColor={parts.backgroundColor || undefined}>
                <ContentContainer horizontalPlacement="center" width="x-large">
                  {flexCardContent}
                </ContentContainer>
              </FullWidthContainer>
              {<SpacerBelowBlock size={parts.spacerBelowBlock} />}
            </>
          );
        }
        return (
          <>
            <ContentContainer horizontalPlacement="center" width="x-large">
              {flexCardContent}
            </ContentContainer>
            {<SpacerBelowBlock size={parts.spacerBelowBlock} />}
          </>
        );
      }
      return (
        <>
          <ContentContainer
            width="medium"
            backgroundColor={parts.backgroundColor || undefined}
            horizontalPlacement="left"
          >
            {flexCardContent}
          </ContentContainer>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MultipleDetails": {
      if (parts.items.length === 0) {
        return <></>;
      }

      return (
        <>
          {/* NB: ikke fjern DetailsArea-wrapper */}
          <DetailsArea>
            {parts.items.map((details, index) => {
              if (details.fullWidth) {
                return (
                  <Details
                    fullWidth={details.fullWidth}
                    id={details.scrollToId || undefined}
                    key={index}
                    summary={details.summary}
                    summaryExtra={details.summaryExtra || undefined}
                    summaryPrefix={details.summaryPrefix || undefined}
                  >
                    {details.richText ? (
                      <RichTextJsonRenderer
                        content={JSON.parse(details.richText)}
                        textTheme="primary"
                        alignTextHorizontally="left"
                      />
                    ) : (
                      // TODO: markdown-feltet skal slettes fra komponenten på sikt
                      <Markdown replaceHeadingLevel1={true}>{details.children}</Markdown>
                    )}
                  </Details>
                );
              }

              return (
                <Details
                  id={details.scrollToId || undefined}
                  key={index}
                  summary={details.summary}
                  summaryExtra={details.summaryExtra || undefined}
                  summaryPrefix={details.summaryPrefix || undefined}
                >
                  {details.richText ? (
                    <RichTextJsonRenderer
                      content={JSON.parse(details.richText)}
                      textTheme="primary"
                      alignTextHorizontally="left"
                    />
                  ) : (
                    // TODO: markdown-feltet skal slettes fra komponenten på sikt
                    <Markdown replaceHeadingLevel1={true}>{details.children}</Markdown>
                  )}
                </Details>
              );
            })}
          </DetailsArea>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MultipleDetailsWithLink": {
      if (parts.items.length === 0) {
        return <></>;
      }

      return (
        <>
          {/* NB: ikke fjern DetailsArea-wrapper */}
          <DetailsArea>
            {parts.items.map((detailsWithLink, index) => {
              if (detailsWithLink.fullWidth) {
                return (
                  <DetailsLookalikeLink
                    fullWidth={detailsWithLink.fullWidth}
                    id={detailsWithLink.scrollToId || undefined}
                    key={index}
                    openUrlInNewTab={detailsWithLink.openUrlInNewTab}
                    text={detailsWithLink.summary}
                    url={detailsWithLink.url}
                  />
                );
              }

              return (
                <DetailsLookalikeLink
                  id={detailsWithLink.scrollToId || undefined}
                  key={index}
                  openUrlInNewTab={detailsWithLink.openUrlInNewTab}
                  text={detailsWithLink.summary}
                  url={detailsWithLink.url}
                />
              );
            })}
          </DetailsArea>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "TextBlock": {
      if (parts.fullWidth) {
        return (
          <>
            <FullWidthContainer backgroundColor={parts.backgroundColor || undefined} paddingOnMobile={true}>
              <ContentContainer horizontalPlacement="center" width="x-large">
                <TextBlock
                  alignButtonHorizontally={parts.alignButtonHorizontally as HorizontalAlignmentType}
                  alignTextHorizontally={parts.alignTextHorizontally as HorizontalAlignmentType}
                  alignTitleHorizontally={parts.alignTitleHorizontally as HorizontalAlignmentType}
                  buttonText={parts.buttonText || undefined}
                  buttonUrl={parts.buttonUrl || undefined}
                  text={parts.text}
                  textTheme={stringToTextTheme(parts.requiredTextTheme)}
                  title={parts.title || undefined}
                />
              </ContentContainer>
            </FullWidthContainer>
            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </>
        );
      }

      return (
        <>
          <ContentContainer
            backgroundColor={parts.backgroundColor || undefined}
            horizontalPlacement="left"
            width="medium"
          >
            <TextBlock
              alignButtonHorizontally={parts.alignButtonHorizontally as HorizontalAlignmentType}
              alignTextHorizontally={parts.alignTextHorizontally as HorizontalAlignmentType}
              alignTitleHorizontally={parts.alignTitleHorizontally as HorizontalAlignmentType}
              buttonText={parts.buttonText || undefined}
              buttonUrl={parts.buttonUrl || undefined}
              text={parts.text}
              textTheme={stringToTextTheme(parts.requiredTextTheme)}
              title={parts.title || undefined}
            />
          </ContentContainer>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "RichTextBlock": {
      if (parts.fullWidth) {
        return (
          <>
            <HorizontalSpacer size={"medium"} />
            <FullWidthContainer backgroundColor={parts.backgroundColor || undefined} paddingOnMobile={true}>
              <ContentContainer horizontalPlacement="center" width="x-large">
                <RichTextBlock
                  alignButtonHorizontally={parts.alignButtonHorizontally as HorizontalAlignmentType}
                  alignTextHorizontally={parts.alignTextHorizontally as HorizontalAlignmentType}
                  alignTitleHorizontally={parts.alignTitleHorizontally as HorizontalAlignmentType}
                  buttonText={parts.buttonText ?? undefined}
                  buttonUrl={parts.buttonUrl ?? undefined}
                  id={parts.scrollToId || undefined}
                  jsonRichTextContent={JSON.parse(parts.text)}
                  textTheme={stringToTextTheme(parts.requiredTextTheme)}
                  title={parts.title ?? undefined}
                />
              </ContentContainer>
            </FullWidthContainer>
            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </>
        );
      }

      return (
        <>
          <ContentContainer
            width="medium"
            backgroundColor={parts.backgroundColor || undefined}
            horizontalPlacement="left"
          >
            <RichTextBlock
              alignButtonHorizontally={parts.alignButtonHorizontally as HorizontalAlignmentType}
              alignTextHorizontally={parts.alignTextHorizontally as HorizontalAlignmentType}
              alignTitleHorizontally={parts.alignTitleHorizontally as HorizontalAlignmentType}
              buttonText={parts.buttonText ?? undefined}
              buttonUrl={parts.buttonUrl ?? undefined}
              id={parts.scrollToId || undefined}
              jsonRichTextContent={JSON.parse(parts.text)}
              textTheme={stringToTextTheme(parts.requiredTextTheme)}
              title={parts.title ?? undefined}
            />
          </ContentContainer>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "ImageGallery": {
      if (parts.items.length === 0) {
        return <></>;
      }

      return (
        <>
          {parts.sliderFullWidth ? (
            <Swiper
              fullWidth={parts.sliderFullWidth}
              isSidebar={isSidebar}
              items={parts.items.map((item, index) => {
                return <Media fallbackAlt="Bilde i bildegalleri" key={index} media={item} />;
              })}
            />
          ) : (
            <ContentContainer horizontalPlacement="center" width="x-large">
              <Swiper
                isSidebar={isSidebar}
                items={parts.items.map((item, index) => {
                  return <Media fallbackAlt="Bilde i bildegalleri" key={index} media={item} />;
                })}
              />
            </ContentContainer>
          )}
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "MediaBlock": {
      if (parts.optionalFullWidth) {
        return (
          <FullWidthContainer>
            <Media
              fallbackAlt="Bilde"
              media={parts.responsiveMedia}
              openUrlInNewTab={parts.openUrlInNewTab}
              url={parts.url ?? undefined}
              width="full"
            />
            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </FullWidthContainer>
        );
      }

      return (
        <ContentContainer horizontalPlacement="center" width="x-large">
          <Media
            fallbackAlt="Bilde"
            media={parts.responsiveMedia}
            openUrlInNewTab={parts.openUrlInNewTab}
            url={parts.url ?? undefined}
            width={parts.setWidthInPixels ?? undefined}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </ContentContainer>
      );
    }

    case "Button": {
      return (
        <>
          <HorizontalContainer horizontalPlacement={parts.alignHorizontally as HorizontalAlignmentType} width="full">
            <LinkButton
              buttonType={parts.buttonType as ButtonType}
              href={parts.nonNullableUrl}
              openUrlInNewTab={parts.openUrlInNewTab}
            >
              {parts.text}
            </LinkButton>
          </HorizontalContainer>
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "SubscriptionCardFilter": {
      return (
        <ContentContainer horizontalPlacement="center" width="x-large">
          <SubscriptionCardFilter isSidebar={isSidebar} filterValues={parts} />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </ContentContainer>
      );
    }

    case "TrenerGalleri": {
      return (
        <Suspense>
          <TrenerGalleri
            isSidebar={isSidebar}
            filters={parts.filter.map((f) => {
              const { __typename, ...rest } = f;
              return rest;
            })}
            heading={parts.heading}
            text={parts.nullableText ?? undefined}
            seeMoreOfThis={parts.buttonText && parts.url ? { text: parts.buttonText, url: parts.url } : undefined}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </Suspense>
      );
    }

    case "Countdown": {
      return (
        <>
          <Countdown
            backgroundColor={parts.backgroundColor ?? undefined}
            fullWidth={parts.fullWidth}
            granularity={mapInputStringToCountdownGranularityEnum(parts.granularity)}
            heading={parts.nullableHeading ?? undefined}
            endDate={parts.date}
            textColor={parts.textColor ?? undefined}
            textSize={parts.textSize}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }
    case "SellingPoints": {
      return (
        <>
          <SellingPoints
            checkMarkColor={parts.checkMarkColor}
            checkMarkSize={parts.checkMarkSize}
            sellingPoints={parts.sellingPointsItems}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "CampaignBox": {
      if (parts.hideBox) {
        return <></>;
      }

      return (
        <>
          <CampaignBlock
            title={parts.nullableCampaignBoxHeading ?? undefined}
            jsonRichTextContent={parts.nullableCampaignBoxText ? JSON.parse(parts.nullableCampaignBoxText) : undefined}
          />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </>
      );
    }

    case "EmbeddedVideoBlock": {
      if (parts.fullWidth) {
        return (
          <FullWidthContainer>
            <EmbeddedVideo url={parts.nonNullableUrl} width="full" />
            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </FullWidthContainer>
        );
      }

      if (parts.setWidthInPixels) {
        return (
          <div>
            <EmbeddedVideo url={parts.nonNullableUrl} width={parts.setWidthInPixels} />
            <SpacerBelowBlock size={parts.spacerBelowBlock} />
          </div>
        );
      }

      return (
        <ContentContainer horizontalPlacement="center" width="x-large">
          <EmbeddedVideo url={parts.nonNullableUrl} />
          <SpacerBelowBlock size={parts.spacerBelowBlock} />
        </ContentContainer>
      );
    }

    default:
      return <Text value={`${parts} - not implemented yet`} />;
  }
}
