import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";
import { ChevronDownIcon } from "../icons/icons";

interface FilterButtonProps {
  filterIsOpen: boolean;
  onClick: () => void;
  selected: boolean;
  selectedFilterCount: number;
  title: string;
}

export const FilterButton = ({ filterIsOpen, onClick, selected, title, selectedFilterCount }: FilterButtonProps) => (
  <button className="filter-button" onClick={onClick} type="button">
    <div
      className={classNames(
        "filter-button__visible",
        filterIsOpen && "filter-button__visible--filter-is-open",
        selected && "filter-button__visible--selected",
      )}
    >
      {title}
      {selectedFilterCount > 0 && ` (${selectedFilterCount})`}
      <div className={classNames("filter-button__chevron", selected && "filter-button__chevron--rotated")}>
        <ChevronDownIcon size="small" />
      </div>
    </div>
  </button>
);

interface FilterButtonWrapperProps {
  children: ReactNode;
  width: number; // setter bredden på filterknappene dynamisk avhengig av hvor mange knapper det er totalt
}

export const FilterButtonWrapper = ({ children, width }: FilterButtonWrapperProps) => (
  <div className="filter-button-wrapper" style={{ width: `${width}%` }}>
    {children}
  </div>
);
