import ReactMarkdown from "react-markdown";

import { classNames } from "@/utils/styling-utils";
import type { HorizontalAlignmentType, WidthType } from "../layout/layout.model";
import type { TextTheme } from "./ui.model";

interface MarkdownProps {
  horizontalPlacement?: HorizontalAlignmentType;
  children: string;
  /**
   * replaceHeadings flytter alle headings én level ned, for å sikre at vi ikke får h1 fra Strapi
   */
  replaceHeadingLevel1?: boolean;
  textTheme?: TextTheme;
  width?: WidthType;
}

export const Markdown = ({ children, horizontalPlacement, replaceHeadingLevel1, textTheme, width }: MarkdownProps) => {
  return (
    <div
      className={classNames(
        "markdown",
        horizontalPlacement && `markdown--horizontal-placement--${horizontalPlacement}`,
        textTheme && `markdown--text-theme--${textTheme}`,
        width && `markdown--width--${width}`,
      )}
    >
      <ReactMarkdown
        components={{
          h1: replaceHeadingLevel1 ? "h2" : "h1",
          h4: "h3",
          h5: "h3",
          h6: "h3",
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};
