import { Heading } from "@/components/base/heading";
import { FullWidthContainer } from "@/components/layout/full-width-container";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { HorizontalSpacer } from "@/components/layout/horizontal-spacer";
import { MoveUpOrDownContainer } from "@/components/layout/move-up-or-down-container";
import { BookingButtonSkeletons } from "@/components/skeletons/booking-button-skeletons";
import { LinkButton } from "@/components/ui/link-button";
import { Text } from "@/components/ui/text";
import type { TextTheme } from "@/components/ui/ui.model";
import { type FilterInput, FilterTypes } from "@/generated/client.generated";
import { FilterTheme } from "@/utils/filter-utils";
import dayjs from "dayjs";
import { useSearchParams } from "next/navigation";
import { Suspense } from "react";
import { ContentContainer } from "../../layout/content-container";
import { VerticalContainer } from "../../layout/vertical-container";
import { BookingNextActivitiesResults } from "./booking-next-activities-results";
import { BookingNextActivitiesWeekdayRow } from "./booking-next-activities-weekday-row";

interface BookingNextActivitiesForFiltersProps {
  currentFilters: ReadonlyArray<FilterInput>;
  showWeekDayFilter: boolean;
  seeMoreOfThis?: {
    url: string;
    text: string;
  };
  take: number;
  title: string;
  backgroundColor?: string;
  textTheme?: string;
  defaultBgColorDark?: boolean;
  activityStartFilter?: string;
  activityEndFilter?: string;
}

export function BookingNextActivitiesForFilters({
  currentFilters,
  take,
  showWeekDayFilter,
  title,
  seeMoreOfThis,
  backgroundColor,
  textTheme,
  defaultBgColorDark,
  activityStartFilter,
  activityEndFilter,
}: BookingNextActivitiesForFiltersProps) {
  const derivedTextTheme: TextTheme = textTheme
    ? textTheme === "dark"
      ? "dark"
      : "light"
    : defaultBgColorDark
      ? "light"
      : "dark";

  const derivedFilterButtonTheme = derivedTextTheme === "light" ? FilterTheme.DARK : FilterTheme.LIGHT;

  const searchParams = useSearchParams();
  const filters = [...currentFilters];

  if (showWeekDayFilter) {
    if (!currentFilters.find((filter) => filter.type === FilterTypes.ToDate)) {
      const toDate = searchParams.get(FilterTypes.ToDate);
      if (toDate) {
        filters.push({ type: FilterTypes.ToDate, value: toDate });
      } else {
        filters.push({ type: FilterTypes.ToDate, value: dayjs().format("YYYY-MM-DD") });
      }
    }
    if (!currentFilters.find((filter) => filter.type === FilterTypes.FromDate)) {
      const fromDate = searchParams.get(FilterTypes.FromDate);
      if (fromDate) {
        filters.push({ type: FilterTypes.FromDate, value: fromDate });
      } else {
        filters.push({ type: FilterTypes.ToDate, value: dayjs().format("YYYY-MM-DD") });
      }
    }
  }

  return (
    <FullWidthContainer
      backgroundColor={backgroundColor}
      defaultBgColorDark={defaultBgColorDark}
      paddingOnMobile={true}
    >
      <HorizontalContainer horizontalPlacement="center" width="full">
        <VerticalContainer width="full" horizontalPlacement="center">
          <Heading type="h2" color={derivedTextTheme}>
            {title}
          </Heading>
          <HorizontalSpacer size="medium" />

          <ContentContainer width="medium">
            {showWeekDayFilter && (
              <BookingNextActivitiesWeekdayRow
                theme={derivedFilterButtonTheme}
                currentFilters={filters}
                activityStartFilter={activityStartFilter}
                activityEndFilter={activityEndFilter}
              />
            )}

            <VerticalContainer width="full">
              <Suspense fallback={<BookingButtonSkeletons number={take} />}>
                <BookingNextActivitiesResults
                  showFullDate={!showWeekDayFilter}
                  currentFilters={filters}
                  take={take}
                  textTheme={derivedTextTheme}
                  activityStartFilter={activityStartFilter}
                  activityEndFilter={activityEndFilter}
                />
              </Suspense>
            </VerticalContainer>
          </ContentContainer>
          <HorizontalSpacer size="large" />

          {seeMoreOfThis && (
            <MoveUpOrDownContainer moveDown={true}>
              <LinkButton buttonType="secondary" href={seeMoreOfThis.url}>
                <Text value={seeMoreOfThis.text} />
              </LinkButton>
            </MoveUpOrDownContainer>
          )}
        </VerticalContainer>
      </HorizontalContainer>
    </FullWidthContainer>
  );
}
