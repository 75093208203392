import dayjs, { type Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import ContentLoader from "react-content-loader";
import { Heading } from "../base/heading";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalContainer } from "../layout/vertical-container";
import { HeadingSkeleton } from "../skeletons/heading-skeleton";
import { Text } from "./text";

interface CountdownProps {
  backgroundColor?: string;
  granularity: CountdownGranularity;
  heading?: string;
  endDate: string;
  textColor?: string;
  textSize: string;
  fullWidth: boolean;
}

interface TimestampFormat {
  days?: string;
  hours?: string;
  minutes?: string;
  seconds?: string;
}

function calculateTimeDiff(formattedEndDate: Dayjs): TimestampFormat {
  const now = dayjs();
  const totalRemainingMilliseconds = formattedEndDate.diff(now, "millisecond");
  const wholeRemainingDays = Math.floor(totalRemainingMilliseconds / (1000 * 60 * 60 * 24));
  const remainingMillisecondsAfterDays = totalRemainingMilliseconds % (1000 * 60 * 60 * 24);
  const wholeRemainingHours = Math.floor(remainingMillisecondsAfterDays / (1000 * 60 * 60));
  const remainingMillisecondsAfterHours = remainingMillisecondsAfterDays % (1000 * 60 * 60);
  const wholeRemainingMinutes = Math.floor(remainingMillisecondsAfterHours / (1000 * 60));
  const remainingMillisecondsAfterMinutes = remainingMillisecondsAfterHours % (1000 * 60);
  const wholeRemainingSeconds = Math.floor(remainingMillisecondsAfterMinutes / 1000);

  return {
    days: wholeRemainingDays.toString(),
    hours: wholeRemainingHours.toString(),
    minutes: wholeRemainingMinutes.toString(),
    seconds: wholeRemainingSeconds.toString(),
  };
}

export function Countdown({
  backgroundColor,
  granularity,
  heading,
  endDate,
  textColor,
  textSize,
  fullWidth,
}: CountdownProps) {
  const formattedEndDate = useMemo(() => dayjs(endDate), [endDate]);
  const [timeDiff, setTimeDiff] = useState<TimestampFormat>({
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  });
  const [endDateHasPassed, setEndDateHasPassed] = useState(false);

  useEffect(() => {
    const intervalID = setInterval(() => {
      const currentTimeDiff = calculateTimeDiff(formattedEndDate);
      const timeDiffToDisplay =
        Number(currentTimeDiff.seconds) >= 0
          ? currentTimeDiff
          : {
              days: "0",
              hours: "0",
              minutes: "0",
              seconds: "0",
            };

      setTimeDiff(timeDiffToDisplay);
      setEndDateHasPassed(Number(currentTimeDiff.seconds) < 0);
    }, 1000);
    if (endDateHasPassed) {
      clearInterval(intervalID);
    }
    return () => {
      clearInterval(intervalID);
    };
  }, [endDateHasPassed, formattedEndDate]);

  return (
    <>
      {endDateHasPassed ? (
        <></>
      ) : timeDiff.days && timeDiff.hours && timeDiff.minutes && timeDiff.seconds ? (
        <HorizontalContainer horizontalPlacement="center">
          <div
            className={`countdown-wrapper${fullWidth ? " countdown-wrapper--fullWidth" : ""}`}
            style={{ backgroundColor: backgroundColor, color: textColor }}
          >
            <HorizontalSpacer size="medium" />
            <div className="countdown-contents">
              {heading && (
                <HorizontalContainer horizontalPlacement="center">
                  <Heading type="h2">{heading}</Heading>
                </HorizontalContainer>
              )}
              {granularity === CountdownGranularity.DAYS && (
                <HorizontalContainer width="full" horizontalPlacement="center">
                  <CountdownUnit number={timeDiff.days!} description="Dager" textSize={textSize} />
                </HorizontalContainer>
              )}
              {granularity === CountdownGranularity.SECONDS && (
                <HorizontalContainer width="full" horizontalPlacement="space">
                  <CountdownUnit number={timeDiff.days} description="Dager" textSize={textSize} />
                  <CountdownUnit number={timeDiff.hours} description="Timer" textSize={textSize} />
                  <CountdownUnit number={timeDiff.minutes} description="Minutter" textSize={textSize} />
                  <CountdownUnit number={timeDiff.seconds} description="Sekunder" textSize={textSize} />
                </HorizontalContainer>
              )}
            </div>
            <HorizontalSpacer size="medium" />
            <HorizontalSpacer size="x-small" />
          </div>
        </HorizontalContainer>
      ) : (
        <HorizontalContainer width="full" horizontalPlacement="center">
          <CountdownSkeleton heading={heading} textSize={typeSafeFromStringToTextSize(textSize)} />
        </HorizontalContainer>
      )}
    </>
  );
}

interface CountdownUnitProps {
  number: string;
  description?: string;
  textSize: string;
}

function CountdownUnit({ number, description, textSize }: CountdownUnitProps) {
  return (
    <div className="countdown-unit">
      <VerticalContainer horizontalPlacement="center" width="full">
        <Text value={number} size={typeSafeFromStringToTextSize(textSize)} weight="black" />
        {description && <Text value={description} size="small" />}
      </VerticalContainer>
    </div>
  );
}

export enum CountdownGranularity {
  SECONDS = 0,
  DAYS = 1,
}

export function mapInputStringToCountdownGranularityEnum(input: string) {
  switch (input) {
    case "days":
      return CountdownGranularity.DAYS;
    case "seconds":
      return CountdownGranularity.SECONDS;
    default:
      return CountdownGranularity.SECONDS;
  }
}

function typeSafeFromStringToTextSize(size: string) {
  switch (size) {
    case "small":
      return "small";
    case "normal":
      return "normal";
    case "large":
      return "large";
    case "x-large":
      return "x-large";
    case "xx-large":
      return "xx-large";
    default:
      return "xx-large";
  }
}

function CountdownSkeleton({ heading, textSize }: { heading?: string; textSize: string }) {
  const spacersBelowHeight = 32;
  switch (textSize) {
    case "small":
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={66 + spacersBelowHeight} width={340}>
            <rect height="76" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
    case "normal":
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={69 + spacersBelowHeight} width={340}>
            <rect height="79" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
    case "large":
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={75 + spacersBelowHeight} width={340}>
            <rect height="85" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
    case "x-large":
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={81 + spacersBelowHeight} width={340}>
            <rect height="91" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
    case "xx-large":
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={99 + spacersBelowHeight} width={340}>
            <rect height="109" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
    default:
      return (
        <VerticalContainer>
          {heading && <HeadingSkeleton type="h2" />}
          <ContentLoader height={99 + spacersBelowHeight} width={340}>
            <rect height="109" width="100%" />
          </ContentLoader>
        </VerticalContainer>
      );
  }
}
