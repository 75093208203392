"use client";
import { VerticalContainer } from "@/components/layout/vertical-container";
import { EventCard } from "@/components/ui/event-card";
import { Swiper } from "@/components/ui/swiper";
import { Text } from "@/components/ui/text";
import { UserContext } from "@/contexts/user-context";
import {
  type BookableEventFragment,
  type FilterInput,
  type ImageFragment,
  useBookableEventsSuspenseQuery,
} from "@/generated/client.generated";
import dayjs from "dayjs";
import { parseAsString, useQueryState } from "nuqs";
import { useContext } from "react";

interface BookingNextEventsResultsSwiperProps {
  currentFilters: ReadonlyArray<FilterInput>;
  take: number;
  isSidebar?: boolean;
}

export function BookingNextEventsResultsSwiper({
  currentFilters,
  take,
  isSidebar,
}: BookingNextEventsResultsSwiperProps) {
  const filters = [...currentFilters];
  const [bookingIdToFilter] = useQueryState("bookingId", parseAsString);

  // get bookable events
  const { data: bookableEventsData, error } = useBookableEventsSuspenseQuery({
    variables: {
      filter: filters,
      skip: 0,
      take: take,
    },
  });
  const { myBookedEvents } = useContext(UserContext);

  if (error) {
    throw error;
  }

  if (!bookableEventsData || bookableEventsData.BookableEvents.length === 0) {
    return (
      <VerticalContainer horizontalPlacement="center">
        <Text style="italic" value="Ingen kommende arrangement" />
      </VerticalContainer>
    );
  }

  const bookableEvents = [...bookableEventsData.BookableEvents].sort(
    (a: BookableEventFragment, b: BookableEventFragment) =>
      dayjs(a.duration.start).isBefore(dayjs(b.duration.start)) ? -1 : 1,
  );

  // Filter out the event that matches the bookingId from the URL.
  const filteredEvents = bookableEvents.filter(
    (bookableEvent) => !bookingIdToFilter || bookableEvent.id !== bookingIdToFilter,
  );

  if (filteredEvents.length === 0) {
    return (
      <VerticalContainer horizontalPlacement="center">
        <Text style="italic" value="Ingen kommende arrangement" />
      </VerticalContainer>
    );
  }

  if (filteredEvents.length === 1) {
    const be = filteredEvents[0];
    if (!(be.event.slug && be.center)) {
      return null;
    }
    return (
      <EventCard
        key={be.id}
        status={
          myBookedEvents.find((myBookedEvent) => myBookedEvent.bookableEvent?.id === bookableEvents[0].id)
            ? "Booked"
            : "Bookable"
        }
        notification={be.notification ?? ""}
        bookableEventId={be.id}
        centerId={be.center.id}
        centerName={be.center.page.title}
        eventName={be.event.page.title}
        eventStart={dayjs(be.duration.start)}
        eventEnd={dayjs(be.duration.end)}
        eventSlug={be.event.slug}
        thumbnailImage={(be.event.page.thumbnailImage as ImageFragment) ?? undefined}
      />
    );
  }
  const items = filteredEvents.flatMap((bookableEvent) => {
    if (!(bookableEvent.event.slug && bookableEvent.center)) {
      return [];
    }
    return (
      <EventCard
        key={bookableEvent.id}
        notification={bookableEvent.notification ?? ""}
        bookableEventId={bookableEvent.id}
        centerId={bookableEvent.center.id}
        centerName={bookableEvent.center.page.title}
        eventName={bookableEvent.event.page.title}
        eventStart={dayjs(bookableEvent.duration.start)}
        eventEnd={dayjs(bookableEvent.duration.end)}
        eventSlug={bookableEvent.event.slug}
        thumbnailImage={(bookableEvent.event.page.thumbnailImage as ImageFragment) ?? undefined}
        status={
          myBookedEvents.find((myBookedEvent) => myBookedEvent.bookableEvent?.id === bookableEvent.id)
            ? "Booked"
            : "Bookable"
        }
      />
    );
  });
  return <Swiper isSidebar={isSidebar} items={items} />;
}
