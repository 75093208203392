import dayjs from "dayjs";

import { FilterTypes } from "@/generated/client.generated";

/**
 * Set filter theme based on light or dark background color of surroundings
 * Default: light
 * Light theme typically for booking pages (light gray background)
 * Dark theme typically for center pages (dark blue background)
 */
export enum FilterTheme {
  DARK = "dark",
  LIGHT = "light",
}

export interface DateFilterSearchParams {
  FROM_DATE?: string;
  TO_DATE?: string;
}

export const getQueryFromDate = (searchParams: DateFilterSearchParams) => {
  return searchParams?.FROM_DATE
    ? { type: FilterTypes.FromDate, value: dayjs(searchParams.FROM_DATE).format("YYYY-MM-DD") }
    : { type: FilterTypes.FromDate, value: dayjs().format("YYYY-MM-DD") };
};

export const getQueryToDate = (searchParams: DateFilterSearchParams) => {
  return searchParams?.TO_DATE
    ? { type: FilterTypes.ToDate, value: dayjs(searchParams.TO_DATE).format("YYYY-MM-DD") }
    : { type: FilterTypes.ToDate, value: dayjs().format("YYYY-MM-DD") };
};
