import {
  type FilterInput,
  FilterTypes,
  type SubscriptionCardFilterFragment,
  type SubscriptionFragment,
  useGetSubscriptionsFilteredSuspenseQuery,
} from "@/generated/client.generated";
import { MultipleSubscriptionCards } from "./multiple-subscription-cards";

interface SubscriptionCardFilterProps {
  isSidebar?: boolean;
  filterValues?: SubscriptionCardFilterFragment;
}

export const SubscriptionCardFilter = ({ isSidebar = false, filterValues }: SubscriptionCardFilterProps) => {
  const currentFilters: FilterInput[] = [
    filterValues?.centerRef?.id ? { type: FilterTypes.Center, value: filterValues.centerRef.id.toString() } : null,
    filterValues?.coverage ? { type: FilterTypes.Coverage, value: filterValues.coverage } : null,
    filterValues?.customerType?.id
      ? { type: FilterTypes.CustomerType, value: filterValues.customerType.id.toString() }
      : null,
    filterValues?.recurrenceType ? { type: FilterTypes.Recurrence, value: filterValues.recurrenceType } : null,
  ].filter((filter): filter is FilterInput => filter !== null);

  const subscriptionCards: ReadonlyArray<SubscriptionFragment> =
    useGetSubscriptionsFilteredSuspenseQuery({
      variables: {
        filter: currentFilters,
      },
    })?.data?.Subscriptions ?? [];

  return (
    <MultipleSubscriptionCards
      isSidebar={isSidebar}
      subscriptionCards={subscriptionCards}
      centerId={filterValues?.centerRef?.id}
    />
  );
};
