export type TextTheme = "primary" | "gray" | "red" | "light" | "dark" | "green";

export const stringToTextTheme = (theme: string | undefined | null): TextTheme => {
  if (theme === "" || !theme) {
    return "primary";
  }
  switch (theme.toLowerCase()) {
    case "primary":
      return "primary";
    case "gray":
      return "gray";
    case "red":
      return "red";
    case "light":
      return "light";
    case "dark":
      return "dark";
    case "green":
      return "green";
    default:
      return "primary";
  }
};

export interface FormatBaseProps {
  align?: "center" | "right";
  color?: TextTheme;
  size?: "small" | "normal" | "large" | "x-large" | "xx-large";
  style?: "italic";
  textDecoration?: "line-through";
  uppercase?: boolean;
  weight?: "normal" | "bold" | "extra-bold" | "black";
}
