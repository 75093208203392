"use client";
import type {} from "@/generated/client.generated";
import ReactPlayer from "react-player/lazy";
import type { WidthType } from "../layout/layout.model";

interface EmbeddedVideoProps {
  url: string;
  width?: WidthType | number;
}

export const EmbeddedVideo = ({ url, width }: EmbeddedVideoProps) => {
  const setWidth = width && width !== "full" ? `${width / 10}rem` : undefined;
  return (
    <div className="embedded-video" style={{ width: setWidth }}>
      <div className="embedded-video__vid">
        <div className="embedded-video__vid__wrapper">
          <ReactPlayer
            muted={false}
            controls={true}
            loop={true}
            width="100%"
            height="100%"
            url={url}
            playsinline={true}
          />
        </div>
      </div>
    </div>
  );
};
