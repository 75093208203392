import type { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";

interface FilterButtonContainerProps {
  children: ReactNode;
  padding?: boolean;
}

export const FilterButtonContainer = ({ children, padding }: FilterButtonContainerProps) => (
  <div className={classNames("filter-button-container", padding && "filter-button-container--padding")}>{children}</div>
);
