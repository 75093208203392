import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface FilterWrapperProps {
  children: ReactNode;
  showSubFilter?: boolean;
}

export const FilterWrapper = ({ children, showSubFilter }: FilterWrapperProps) => (
  <div className={classNames("filter-wrapper", showSubFilter && "filter-wrapper--show-sub-filter")}>{children}</div>
);
