import type { ImageFragment } from "@/generated/client.generated";
import dayjs from "dayjs";
import { Link } from "../base/link";
import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalContainer } from "../layout/vertical-container";
import { Text } from "./text";
require("dayjs/locale/nb");
dayjs.locale("nb");
import { paths } from "@/utils/paths-utils";
import { capitalizeWord } from "@/utils/string-utils";
import { Button } from "../base/button";
import { Heading } from "../base/heading";
import { IconGroupLocations } from "../icons/icon-group-locations";
import { CheckIcon } from "../icons/icons";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Image } from "./image";

interface EventCardProps {
  notification: string;
  bookableEventId: string;
  centerId: string;
  centerName: string;
  eventName: string;
  eventStart: dayjs.Dayjs;
  eventEnd: dayjs.Dayjs;
  eventSlug: string;
  thumbnailImage: ImageFragment;
  status: "Booked" | "Bookable" | "Attended";
}

export const EventCard = ({
  status = "Bookable",
  notification,
  bookableEventId,
  centerId,
  centerName,
  eventName,
  eventStart,
  eventEnd,
  eventSlug,
  thumbnailImage,
}: EventCardProps) => {
  const times: Array<string> = notification?.split("\n") ?? [];

  return (
    <div className="event-card">
      <Link href={paths.event(eventSlug, { bookingId: bookableEventId, centerId: centerId })} textDecoration="none">
        <div className="event-card__media">
          {thumbnailImage && (
            <Image
              alt={thumbnailImage.alternativeText ?? eventName}
              src={thumbnailImage.url}
              placeholder={thumbnailImage.placeholder ?? undefined}
              width={thumbnailImage.width}
              height={thumbnailImage.height}
            />
          )}
        </div>

        <div className="event-card__content">
          <VerticalContainer height="full" horizontalPlacement="left" verticalPlacement="space" width="full">
            <VerticalContainer height="full" width="full">
              <HorizontalContainer width="full" horizontalPlacement="space" verticalPlacement="center">
                <VerticalContainer>
                  <Text
                    size="small"
                    value={
                      eventStart.format("DD.MM") === eventEnd.format("DD.MM")
                        ? capitalizeWord(eventStart.format("dddd DD.MM"))
                        : `${eventStart.format("DD.MM")} - ${eventEnd.format("DD.MM")}`
                    }
                  />
                  <HorizontalSpacer size="x-small" />
                </VerticalContainer>

                <IconGroupLocations iconLabels={[centerName]} textSize="small" />
              </HorizontalContainer>

              <Heading type="h2">{eventName}</Heading>

              {times.length > 0 && (
                <div className="event-card__content__times event-card__content__times--gradient">
                  {times.map((time) => (
                    <Text value={time} size="small" key={time} />
                  ))}
                </div>
              )}
            </VerticalContainer>

            {status === "Bookable" && <Button buttonType={"secondary"}>Les mer og meld deg på</Button>}

            {status === "Booked" && (
              <HorizontalContainer verticalPlacement="center">
                <Text value="Du er påmeldt!" weight="bold" />
                <VerticalSpacer size="x-small" />
                <Text value="Velkommen!" />
              </HorizontalContainer>
            )}

            {status === "Attended" && (
              <Button buttonType={"primary"} disabled={true}>
                <HorizontalContainer verticalPlacement="center" noWrap={true}>
                  <Text value="Deltok" weight="bold" />
                  <VerticalSpacer size="x-small" />
                  <CheckIcon size="small" />
                </HorizontalContainer>
              </Button>
            )}
          </VerticalContainer>
        </div>
      </Link>
    </div>
  );
};
