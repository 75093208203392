"use client";
import { type FilterInput, FilterTypes } from "@/generated/client.generated";
import { handleDateFilters } from "@/utils/booking-utils";
import type { FilterTheme } from "@/utils/filter-utils";
import { ROOT_URL } from "@/utils/paths-utils";
import { setQueryStringValues } from "@/utils/query-string-utils";
import dayjs from "dayjs";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { FilterWeekdays } from "../../filter/filter-weekdays";
import { HorizontalSpacer } from "../../layout/horizontal-spacer";

interface BookingNextActivitiesWeekdayRowProps {
  currentFilters: ReadonlyArray<FilterInput>;
  theme?: FilterTheme;
  activityStartFilter?: string;
  activityEndFilter?: string;
}

export function BookingNextActivitiesWeekdayRow({
  currentFilters,
  theme,
  activityStartFilter,
  activityEndFilter,
}: BookingNextActivitiesWeekdayRowProps) {
  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  // filter cache used to allow GUI to update immediately before query params
  const [currentFiltersCache, setCurrentFiltersCache] = useState<Array<FilterInput>>([...currentFilters]);

  useEffect(() => {
    setCurrentFiltersCache([...currentFilters]);
  }, [currentFilters]);

  // Update query parameters to match either supplied filters or currentFiltersCache as fallback
  const updateQueryParameters = (filters?: Array<FilterInput>) => {
    const newFilters = filters ?? currentFiltersCache;
    const dateFilters = newFilters.filter(
      (filter) => filter.type === FilterTypes.FromDate || filter.type === FilterTypes.ToDate,
    );
    const newQueryString = setQueryStringValues(
      dateFilters.map((dateFilter) => {
        return { queryString: dateFilter.type, value: dayjs(dateFilter.value).format("YYYY-MM-DD") };
      }),
      searchParams,
    );

    //Here we do not want the scroll position to jump to the top after updating query params
    //as this whole view is likely far down on a page
    router.push(`${ROOT_URL}/${pathName}?${newQueryString}`, { scroll: false });
  };

  // updates currentFilterCache based on supplied modified filters
  // updateQueryParametersImmediately can be used to trigger updateQueryParameters() immediately rather than after button press, click outside filter or other navigation
  const selectMultipleFilters = (selectedFilters: Array<FilterInput>, updateQueryParametersImmediately: boolean) => {
    const newDateFilters = handleDateFilters(selectedFilters, currentFiltersCache);
    setCurrentFiltersCache(newDateFilters);

    if (updateQueryParametersImmediately) {
      updateQueryParameters(newDateFilters);
    }
  };

  return (
    <>
      <FilterWeekdays
        onClickHandler={{
          onClick: (date: string) => {
            selectMultipleFilters(
              [
                {
                  type: FilterTypes.FromDate,
                  value: dayjs(date).format("YYYY-MM-DD"),
                },
                {
                  type: FilterTypes.ToDate,
                  value: dayjs(date).format("YYYY-MM-DD"),
                },
              ],
              true,
            );
          },
        }}
        currentFilters={currentFilters}
        theme={theme}
        activityStartFilter={activityStartFilter ?? undefined}
        activityEndFilter={activityEndFilter ?? undefined}
      />
      <HorizontalSpacer size="medium" />
    </>
  );
}
