import { classNames } from "@/utils/styling-utils";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalContainer } from "../layout/vertical-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Text } from "../ui/text";
import type { FormatBaseProps } from "../ui/ui.model";
import { MapPinIcon } from "./icons";

interface IconGroupLocationsProps {
  iconLabels: Array<string>;
  textSize?: FormatBaseProps["size"];
  threeColumns?: boolean;
}

export const IconGroupLocations = ({ iconLabels, textSize, threeColumns = false }: IconGroupLocationsProps) => {
  return (
    <HorizontalContainer horizontalPlacement="left">
      {iconLabels.map((iconLabel, index) => (
        <div
          className={classNames("icon-group-locations", threeColumns ? "icon-group-locations--three-columns" : "")}
          key={iconLabel + index}
        >
          <VerticalContainer>
            <HorizontalContainer verticalPlacement="center" noWrap={true}>
              <MapPinIcon size="small" />
              <VerticalSpacer size="xx-small" />
              <Text size={textSize} value={iconLabel} noWrap={true} />
            </HorizontalContainer>
            <HorizontalSpacer size="x-small" />
          </VerticalContainer>

          {index !== iconLabels.length - 1 && <VerticalSpacer size="large" />}
        </div>
      ))}
    </HorizontalContainer>
  );
};
